import * as React from "react";
import "./page-link.scss";
import {Link} from "gatsby-plugin-react-i18next";

const PageLink = (props: { to: string, content: string }) => {
    return (
        <div className="page-link">
            <div className="page-link_hr_transparent"></div>
            <Link to={props.to}>{props.content}</Link>
            {typeof window !== 'undefined' && props.to === window.location.pathname ? <div className="page-link_hr"></div> : <div className="page-link_hr_transparent"></div>}
        </div>
    );
}
export default PageLink;